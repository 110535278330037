import React from "react";
import { MailIcon } from "@heroicons/react/outline";

const NewsEmailSignup = () => {
  return (
    <form
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="news"
      className="p-4 mt-8 rounded-lg bg-zinc-100 xl:max-w-xl"
    >
      <input type="hidden" name="bot-field" aria-hidden="true" />
      <input type="hidden" name="form-name" value="news" aria-hidden="true" />
      <div className="relative text-slate-900">
        <input
          type="email"
          name="email"
          required
          aria-required
          placeholder="sarah.collins@cells.ag"
          className="w-full h-10 pl-8 pr-3 text-base placeholder-gray-600 border rounded-full focus:invalid:text-red-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:invalid:border-red-500 focus:invalid:ring-red-500"
          id="emailInput"
        />
        <label
          className="absolute inset-y-0 left-0 flex items-center px-2 mx-1 pointer-events-none"
          htmlFor="emailInput"
        >
          <MailIcon className="w-4 h-4 text-slate-600" />
        </label>
      </div>
      <button
        type="submit"
        className="w-full px-4 py-2 mt-2 text-white bg-red-500 rounded-full"
      >
        Subscribe to Cultura News
      </button>
    </form>
  );
};

export default NewsEmailSignup;
