import React from "react";
import NewsEmailSignup from "../components/shared/forms/NewsEmailSignup";
import {
  MainBody,
  MainHeading,
  SecondaryHeading,
} from "../components/shared/Headings";
import Layout from "../components/shared/Layout";
import Seo from "../components/seo/Seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "@imgix/gatsby/src/modules/gatsby-transform-url/gatsbyPluginImage";

export default function News() {
  const b = [
    "globe-news-sm.jpg",
    "shipping-news-sm.jpg",
    "cells-news-sm.jpg",
    "spinner-news-sm.jpg",
    "facility-news-sm.jpg",
    "molecule-news-sm.jpg",
  ];
  return (
    <Layout>
      <Seo title="Cultura News" />
      <section className="grid px-4 py-10 xl:grid-cols-6 xl:px-0 xl:container xl:m-auto xl:py-16">
        <div className="flex flex-col xl:col-span-4">
          <MainHeading>Cultured Meat News & Market Research</MainHeading>
          <MainBody>
            Cultura News provides pressional reports, analysis and updates about
            the cultured meat industry.
          </MainBody>
          <NewsEmailSignup />
        </div>
      </section>
      <section className="px-4 py-16 mx-auto xl:container xl:px-0 ">
        <SecondaryHeading
          additionalClassNames={"text-slate-900 font-bold text-3xl"}
        >
          Industry insights
        </SecondaryHeading>
        <div className="grid w-full grid-cols-2 gap-4 mt-10 xl:grid-cols-3">
          {b.map((x, i) => (
            <GatsbyImage
              image={getGatsbyImageData({
                src: `https://cultura.imgix.net/${x}`,
                aspectRatio: 0.625,
                imgixParams: { auto: ["format", "compress"] },
                layout: "constrained",
              })}
              layout="constrained"
              aspectRatio={0.625}
              className="col-span-1"
              alt=""
              key={i}
            />
          ))}
        </div>
      </section>
    </Layout>
  );
}
