import React from "react";
import classNames from "../../utils/twClassNames";

export const SecondaryHeading = ({ additionalClassNames, children }) => {
  const additionalClasses = additionalClassNames
    ? additionalClassNames
    : "text-slate-900 font-medium mx-4 text-3xl xl:mx-0";
  return (
    <h2
      className={classNames(
        "tracking-tight md:text-5xl max-w-xs md:max-w-lg",
        additionalClasses
      )}
    >
      {children}
    </h2>
  );
};

export const SecondaryBody = ({ additionalClassNames, children }) => {
  const additionalClasses = additionalClassNames
    ? additionalClassNames
    : "mx-4 xl:mx-0";
  return (
    <p
      className={classNames(
        "mt-2 md:text-lg lg:max-w-2xl text-slate-900",
        additionalClasses
      )}
    >
      {children}
    </p>
  );
};

export const MainHeading = ({ children }) => {
  return (
    <h1 className="h-auto text-5xl font-semibold tracking-tight text-slate-900 lg:text-8xl 2xl:text-9xl">
      {children}
    </h1>
  );
};

export const MainBody = ({ children }) => {
  return (
    <p className="block pr-1 mb-2 ml-1 text-lg text-slate-900 md:text-3xl md:max-w-3xl 2xl:max-w-none 2xl:text-5xl">
      {children}
    </p>
  );
};

export const SmallSecondaryHeading = ({ padding, children }) => {
  return (
    <div className="w-full px-12 mb-4 xl:px-8">
      <h2 className="text-xl font-bold tracking-tight text-slate-900 lg:text-3xl">
        {children}
      </h2>
    </div>
  );
};

export const SmallSecondaryHeadingSpan = ({ children }) => {
  return <span className="text-slate-500">{children}</span>;
};
